import { template as template_300a452b2c0a45a2b8d102468bdf7128 } from "@ember/template-compiler";
const WelcomeHeader = template_300a452b2c0a45a2b8d102468bdf7128(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
