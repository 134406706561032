import { template as template_eca73b554e3646cf99da113c5dd5f3e1 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_eca73b554e3646cf99da113c5dd5f3e1(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
