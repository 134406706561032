import { template as template_8a066985f56b4aef90c3ffbc27b0edf2 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_8a066985f56b4aef90c3ffbc27b0edf2(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
