import { template as template_46b2d3faf9be4ebf9d5a9ca8b1308d26 } from "@ember/template-compiler";
import { on } from "@ember/modifier";
const BulkSelectCell = template_46b2d3faf9be4ebf9d5a9ca8b1308d26(`
  <td class="bulk-select topic-list-data">
    <label for="bulk-select-{{@topic.id}}">
      <input
        {{on "click" @onBulkSelectToggle}}
        checked={{@isSelected}}
        type="checkbox"
        id="bulk-select-{{@topic.id}}"
        class="bulk-select"
      />
    </label>
  </td>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectCell;
